.loginContainer {
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer {
    padding: 3em 3em 1em 3em;
    min-width: 15vw;
    height: 50vh;
}