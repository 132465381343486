.wineCategoryContainer {
    height: 450px;
    width: 25%;
    min-width: 250px;
    padding: 1em
}

@media only screen and (max-width: 600px) {
    .wineCategoryContainer {
        height: 450px;
        width: 90%;
        padding: 1em
    }
}