body {
    background-color: #f9f9f9;
}

.appBar {
    z-index: 1201 !important;
    height: 4em;
}

.root {
    display: flex;
    margin-top: 3em;
}

.drawer {
    width: 240px;
    flex-shrink: 0;
}

.smDrawer {
    width: 180px;
    flex-shrink: 0;
}

.smDrawerPaper {
    width: 180px;
}

.drawerPaper {
    width: 240px;
}

.drawerContainer {
   overflow: auto;
    margin-top: 5em;
}

.content {
    flex-grow: 1;
    padding: .5em;
}
