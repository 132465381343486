.fieldModifier {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1em 0;
}

.floatingBoxContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 2em 1em 2em 1em;
}

.floatingBoxInnerPadding {
    padding: 2em;
    height: 100%;
}

.floatingBoxSectionTitle {
    font-size: 1.5em;
    margin-bottom: 1em;
    border-bottom: 1px solid black;
}

.valid fieldset {
    border-color: green !important;
}

.fieldContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.controlledField {
    width: 100%;
    margin: .4em 0 .4em 0 !important
}